import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface droneStateType {
  showNavList: { [key: string]: boolean };
}

const initialState: droneStateType = {
  showNavList: {},
};

export const droneNavState = createSlice({
  name: "eventsSetting",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    navOpen: (state: droneStateType, action: PayloadAction<string>) => {
      state.showNavList[action.payload] = true;
    },
    navOff: (state: droneStateType, action: PayloadAction<string>) => {
      delete state.showNavList[action.payload];
    },
  },
});

export const { navOpen, navOff } = droneNavState.actions;

export default droneNavState.reducer;
